import React , {Component} from "react"
import Layout from "../components/layout/layout"
import SEOComponent from "../components/seo"
import AmberScore from "../components/reusable/AmberScore";
import CustomersMap from '../components/customers/CustomersMap';
import CustomersStories from '../components/customers/CustomersStories';
import CustomersClients from '../components/customers/CustomersClients';

class Customers extends Component {
    
    constructor(props){  
        super(props);  
        if(typeof window !== `undefined`){
            window.location.href = 'https://www.infeedo.ai/customers'; 
        }
   }  
   
    render(){
        return (
            <>
            {true ? null  : 
            <Layout page = 'customers'>
            <SEOComponent title="Amber by inFeedo | Customer love" description="Amber is an AI chatbot that talks to your employees to help you identify those who are unhappy, disengaged or about to leave. She is loved by 100+ CHROs and 300,000+ employee spread across 30+ countries." />
            <CustomersClients />
            <CustomersStories />
            <CustomersMap />
            <AmberScore page = 'customers'/>
        </Layout>
            }
            </>
        )
    }

}

export default Customers;