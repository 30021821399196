import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from "prop-types";

export default class VideoModal extends Component {
    render() {
        const {is_active, video_id, onClose} = this.props;
        return (
            <div className={classnames('modal', {'is-active': is_active})}>
            <div className="modal-background" onClick={onClose}></div>
            <div className="modal-content height-home-video">
                <div className="w-embed-youtubevideo youtube-2"><iframe title="video" src={`https://www.youtube.com/embed/${video_id}?rel=0&amp;controls=1&amp;autoplay=1&amp;mute=0&amp;start=0`} frameBorder="0" className="iframe-video" allow="autoplay; encrypted-media" allowFullScreen=""></iframe></div>
            </div>
            <button className="modal-close is-large" aria-label="close" onClick={onClose}></button>
            </div>
        )
    }
}

VideoModal.propTypes = {
    is_active: PropTypes.bool.isRequired,
    video_id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
}